<script>
    import Widgets from '../../layout/widget/Widgets'
    import WidgetItem from '../../layout/widget/WidgetItem'
    import WidgetCotacoesStats from './WidgetCotacoesStats';
    import CotacaoOptions from '../menuCotacao'
    import DashOptions from '../../dashboard/components/DashOptions';
    import novaCotacaoWindow from '../windows/cotacaoremocao'

    let schema = {
        'cotacao': {
            'abertas': 27,
            'semLance': 2,
            'comLance': 25,
            'solicitacoes': {
                'hoje': 2,
                'mes': 32,
                'total': 112
            }
        }
    }

    export default {
        name: 'Cotacoes',
        components: {
            Widgets,
            WidgetItem,
            WidgetCotacoesStats,
            DashOptions
        },
        data () {
            return {
                cotacao: {
                    loading: false,
                    data: JSON.parse(JSON.stringify(schema.cotacao))
                },
                showDashOptions: false
            }
        },
        computed: {
            menuDashOptions () {
                return CotacaoOptions
            }
        },
        mounted () {
            window.setTimeout(() => {
                this.showDashOptions = true
            }, 400)
        },
        methods: {
            novaCotacaoWindow: novaCotacaoWindow
        },
        meta: {
            title: 'Cotações',
            name: 'Cotações'
        }
    }
</script>

<template>
    <div class="wrapper-lg">
        <div>
            <widgets :maxSlots="12" :adjusts="[{'screen': 0, 'slots': 12}, {'screen': 0, 'slots': 4}]" ref="widgets">
                <widget-item :wslot="2" :hslot="2">
                    <widget-cotacoes-stats
                            title="Em aberto"
                            :count="[{title: null, value: cotacao.data.abertas}]"
                    >
                        <slot slot="link"><a>Visualizar</a></slot>
                    </widget-cotacoes-stats>
                </widget-item>

                <widget-item :wslot="2" :hslot="2">
                    <widget-cotacoes-stats
                            title="Cotações sem lance"
                            :count="[{title: null, value: cotacao.data.semLance}]"
                    >
                        <slot slot="link"><a>Visualizar</a></slot>
                    </widget-cotacoes-stats>
                </widget-item>

                <widget-item :wslot="2" :hslot="2">
                    <widget-cotacoes-stats
                            title="Cotações com lance"
                            :count="[{title: null, value: cotacao.data.semLance}]"
                    >
                        <slot slot="link"><a>Visualizar</a></slot>
                    </widget-cotacoes-stats>
                </widget-item>

                <widget-item :wslot="6" :hslot="2">
                    <widget-cotacoes-stats
                            title="Solicitações de remoção para cotar"
                            :count="[
                            {title: 'Hoje', value: cotacao.data.solicitacoes.hoje},
                            {title: 'No mês', value: cotacao.data.solicitacoes.mes},
                            {title: 'Total em aberto', value: cotacao.data.solicitacoes.total}
                        ]"
                    >
                        <slot slot="link"><a>Ver solicitações de remoção</a></slot>
                    </widget-cotacoes-stats>
                </widget-item>
            </widgets>
        </div>

        <div>
            <dash-options @click="novaCotacaoWindow" v-if="showDashOptions" class="dash-remocao" :menu="menuDashOptions" />
        </div>
    </div>
</template>
