export default [
  {
    name: 'nova-cotacao',
    title: 'Nova Cotação',
    description: 'Inicie um processo de cotação de custo de remoção',
    icon: 'hand-holding-usd',
    iconType: 'fa',
    iconStyle: 'light',
    clickEvent: function (m) {
      this.$emit('click', m)
    }
  },
  {
    name: 'relatorios',
    title: 'Relatórios',
    description: 'Gere relatórios gerenciais com informações sobre remoções',
    icon: 'clipboard-list-check',
    iconType: 'fa',
    iconStyle: 'light',
    url: '#'
  }
]
